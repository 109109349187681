import BaseLayout from "@components/BaseLayout"
import Blog from "@components/Blog"
import CategorySelect from "@components/CategorySelect"
import Hero from "@components/Hero"
import Seo from "@components/Seo"
import routes from "@utils/routes"
import { graphql } from "gatsby"
import React from "react"
import { useSearch } from "@utils/hooks/useSearch"
import SearchInput from "@components/SearchInput"
import { useTranslate } from "@utils/hooks/useTranslate"

function CategoryBlogList({ data, pageContext }) {
  const allPosts = data?.allMarkdownRemark?.edges ?? []
  const { searchTerm, search, results } = useSearch(
    allPosts,
    data.siteSearchIndex.index
  )
  const translate = useTranslate()
  return (
    <BaseLayout
      hero={
        <Hero
          title={pageContext.category}
          subtitle="blog.hero.subtitle"
          rightSide={
            <SearchInput
              placeholder={translate("blog.searchPlaceholder")}
              defaultValue={searchTerm}
              onChange={event => search(event.target.value)}
            />
          }
        />
      }
      underMenuItem={routes.blog}
      isDark
    >
      <CategorySelect
        categories={data.allCategories.data}
        selectedCategory={pageContext.categorySlug}
      />
      <Seo title="Blog" />
      <Blog {...{ posts: searchTerm ? results : allPosts, pageContext }} />
    </BaseLayout>
  )
}

export const query = graphql`
  query CategoryBlogList($category: String!, $postsLanguage: String!) {
    siteSearchIndex {
      index
    }
    allCategories: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: $postsLanguage } } }
    ) {
      data: distinct(field: frontmatter___category)
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/posts/*.md" }
        frontmatter: {
          category: { eq: $category }
          language: { eq: $postsLanguage }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            date
            description
            title
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            categorySlug
          }
          timeToRead
        }
      }
    }
  }
`

export default CategoryBlogList
