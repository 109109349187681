import { useTranslate } from "@utils/hooks/useTranslate"
import { Link } from "gatsby-plugin-intl"
import React from "react"
import slugify from "slugify"
import * as Style from "./CategorySelect.styles"

function CategorySelect({ categories = [] }) {
  const translate = useTranslate()
  const mappedCategories = categories.map(value => ({
    label: value,
    value: slugify(value, { lower: true }),
  }))
  const allCategories = [
    { label: translate("blog.allCategories"), value: "blog" },
  ].concat(mappedCategories)

  return (
    <Style.Wrapper>
      {allCategories.map(({ value, label }, index) => (
        <Link
          key={index}
          to={`/${value}`}
          partiallyActive
          activeClassName="active"
        >
          {label}
        </Link>
      ))}
    </Style.Wrapper>
  )
}

export default CategorySelect
