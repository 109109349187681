import Content from "@components/Content"
import PostItem from "@components/PostItem"
import { useInfiniteScroll } from "@utils/hooks/useInfiniteScroll"
import React, { useRef, useState } from "react"
import * as Style from "./Blog.styles"
import { BlankSlate } from "./BlankSlate"

function Blog({ posts: postList, pageContext }) {
  const { total } = pageContext
  const postsToShow = 9
  const [postsQuantity, setPostsQuantity] = useState(postsToShow)
  const [canFetch, setCanFetch] = useState(false)
  const containerRef = useRef(null)
  const imageHeight = 200

  const onLoadMore = () => {
    if (
      Math.ceil(total / postsToShow) === Math.ceil(postsQuantity / postsToShow)
    ) {
      return
    }
    setPostsQuantity(postsQuantity + postsToShow)
    setCanFetch(false)
  }

  const loadMore = () => {
    setCanFetch(true)
    onLoadMore()
  }
  useInfiniteScroll(containerRef, canFetch, () => loadMore(), imageHeight)

  return (
    <Style.Wrapper>
      <Content sx={{ paddingY: [0, 50] }} flexDirection="column">
        <Style.PostGrid ref={containerRef}>
          {postList
            .slice(0, postsQuantity)
            .map(
              (
                {
                  node: {
                    frontmatter: { category, description, title, image },
                    fields: { slug, categorySlug },
                    timeToRead,
                  },
                },
                index
              ) => (
                <PostItem
                  key={index}
                  slug={slug}
                  categorySlug={categorySlug}
                  category={category}
                  timeToRead={timeToRead}
                  title={title}
                  description={description}
                  image={image}
                />
              )
            )}
        </Style.PostGrid>
        {!postList.length && <BlankSlate />}
      </Content>
    </Style.Wrapper>
  )
}

export default Blog
