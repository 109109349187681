import React from "react"
import { Box, Flex } from "rebass"

export const Wrapper = props => (
  <Flex
    alignItems="center"
    flexDirection="column"
    width="100%"
    sx={{ paddingX: [0, 3] }}
    {...props}
  />
)

export const PostGrid = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    display="grid"
    width="100%"
    sx={{
      gridGap: 3,
      gridTemplateColumns: [
        "repeat(auto-fill, minmax(100%, 1fr))",
        "repeat(auto-fill, minmax(300px, 1fr))",
      ],
    }}
    {...props}
  />
))
