import Content, { WidthLimitation } from "@components/Content"
import React from "react"

export const Wrapper = ({ children }) => (
  <Content
    backgroundColor="blue"
    padding={0}
    paddingX={3}
    paddingBottom={3}
    sx={{
      textAlign: "left",
      paddingX: [3],
      maxWidth: "100%",
      justifyContent: "center",
      div: {
        justifyContent: "flex-start",
        flexWrap: "wrap",
        width: "100%",
        a: {
          color: "white",
          fontSize: 2,
          mr: 3,
          mb: [3, 3, 0],
          fontFamily: "body",
          fontWeight: "body",
          textDecoration: "none",
          borderRadius: 40,
          paddingY: "8px",
          paddingX: 16,
          borderColor: "#0C62A4",
          borderWidth: 1,
          borderStyle: "solid",
          "&.active": {
            fontWeight: "bold",
            opacity: 1,
            backgroundColor: "#0C62A4",
          },
        },
      },
    }}
  >
    <WidthLimitation>{children}</WidthLimitation>
  </Content>
)
