import { useEffect } from "react";

export const useInfiniteScroll = (ref, isFetching, handler, offset = 0) => {
  useEffect(() => {
    const handleScroll = event => {
      if (typeof window === 'undefined' || !ref.current || ref.current.contains(event.target) || isFetching) {
        return
      }
      if (window.scrollY + ref.current.offsetTop >= ref.current.clientHeight - offset) {
        handler()
      }
    }
    document.addEventListener("scroll", handleScroll)
    document.addEventListener("touchend", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
      document.removeEventListener("touchend", handleScroll)
    }
  }, [ref, handler, isFetching, offset])
}
