import React from "react"
import Translate from "@components/Translate"
import { Box } from "rebass"

const Wrapper = p => (
  <Box
    sx={{
      fontFamily: "heading",
      fontSize: 4,
      color: "white",
      textAlign: "center",
      width: "100%",
    }}
    {...p}
  />
)

export function BlankSlate() {
  return (
    <Wrapper>
      <Translate id="blog.blankSlateMessage" />
    </Wrapper>
  )
}
